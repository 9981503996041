import * as React from 'react';
import { RouteChildrenProps } from 'react-router';
import styled from 'styled-components';

import { Seo } from '../../../utils/seo/Index';
import { Top } from './sections/Top';
import { Section } from './sections/Section';
import { TextSection } from './sections/TextSection';
import { useSiteContext } from '../../../utils/hooks/useSiteContext';

const Home: React.FC<RouteChildrenProps> = ({ location }) => {
  const { routeConfig } = useSiteContext();

  return (
    <>
      <Seo
        title="PrivatMegleren - Nordeas Eiendomsmeglerkjede"
        description="Søk blant PrivatMeglerens eiendommer til salgs, eller ta kontakt med våre eiendomsmeglere for verdivurdering."
        imageTitle="/privatMegleren-eiendomsmegler-kjøp-salg-eiendom-leilighet-hus-bolig"
        url={location.pathname}
        canonical={`https://privatmegleren.no${location.pathname}`}
      />
      <Top />

      <TextSection />
      <Sections>
        <Section
          direction="left"
          type="forstegangsselgeren"
          title="Førstegangsselgeren"
          bodyText={`<p> Når livet skjer, tar vi oss av detaljene. Om du skal selge for første, andre eller siste gang.</p>`}
          buttonText="Les mer"
          href={routeConfig?.forstegangsselgeren.url}
          bg="https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/Forsidebilde_Forstegangsselgeren.jpg"
        />
        <Section
          direction="right"
          type="presisjon2"
          title="Boligsalg med Presisjon"
          bodyText={`<p> Ingen boligkjøpere er like, derfor bør ingen boligannonser være det heller. For vi forelsker oss i den samme boligen av ulike grunner.</p>`}
          buttonText="Les mer"
          href={routeConfig?.presisjon.url}
          bg="https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/Bilde-forsiden-PrivatMegleren.no-883-x-530-Kjøkken.jpg"
        />
        <Section
          direction="left"
          type="detaljenesomselger"
          title="Detaljene som selger"
          bodyText={`<p>En attraktiv bolig er resultatet av bevisste valg og et øye for vakre detaljer. Les om detaljer som selger her.</p>`}
          buttonText="Les mer"
          href={routeConfig?.detaljene_som_selger.url}
          bg="https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/DTS_Forsidebilde.jpg"
        />
        <Section
          direction="right"
          type="hvaerdinboligverdt"
          title="Ønsker du en verdivurdering?"
          bodyText={`<p>La en våre eiendomsmeglere bistå deg i salget.</p>`}
          href={routeConfig?.valuation.url}
          bg="https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/Hva-er-din-eiendom-verdt.jpg"
        />
        <Section
          direction="left"
          type="boligreise"
          title="Alt du lurer på – fra start til solgt"
          bodyText={`<p>I vår nye serie utfordres vi av Pia Tjelta til å avsløre hvordan du lykkes med kjøp og salg av eiendom.</p>`}
          buttonText="Les mer"
          href={routeConfig?.boligreise.url}
          bg="https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/boligreise-new.jpg"
        />
        <Section
          direction="right"
          type="kvadrat"
          title="Kvadrat gir deg den aller beste opplevelsen – hele veien til solgt"
          bodyText={`<p>For å gi deg en enda bedre opplevelse av boligsalget, har vi utviklet en løsning som gjør det litt enklere når du skal selge eiendom. Kvadrat gir deg overblikket og kontrollen fra vi setter i gang, og er med deg hele veien til solgt.</p>`}
          buttonText="Les mer"
          href={routeConfig?.kvadrat.url}
          bg="https://cdn.reeltime.no/pm_assets/forside/Kvadrat.jpg"
        />

        <Section
          direction="left"
          type="pmverdi"
          title="Hva er boligen verdt?"
          bodyText={`<p>Sjekk hva boligen din er verdt med vår boligkalkulator.</p>`}
          buttonText="Søk adresse"
          href={routeConfig?.settprispaa.url}
          bg="https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/Verdi.jpg"
        />
        <Section
          direction="right"
          type="digitaltmøte"
          title="Book et digitalt møte"
          bodyText={`<p>I forkant av et salg ønsker mange å orientere seg om eventuell salgsprosess, og hva som vil være viktig for at eiendommen skal få full uttelling for sitt potensiale i markedet.</p><p>Book et digitalt møte hvor vi snakker om ditt boligsalg, uten forpliktelse.</p>`}
          buttonText="Book et møte"
          href={routeConfig?.digitalMeeting.url}
          bg="https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/Book-et-digitalt-mote.jpg"
        />
        <Section
          direction="left"
          type="boligbytte"
          title="PrivatMegleren Boligbytte"
          bodyText={`<p>Så enkelt skal det være å flytte</p>`}
          href={routeConfig?.boligbytte.url}
          bg="https://cdn.reeltime.no/pm_assets/public/images/forside_boligbytte.jpg"
        />

        <Section
          direction="right"
          type="kjop"
          title="PrivatMegleren Kjøp"
          bodyText={`<p>Nå får du også bistand ved kjøp av eiendom</p>`}
          href={routeConfig?.kjop.url}
          bg="https://cdn.reeltime.no/pm_assets/forside/kjop.jpg"
        />

        <Section
          direction="left"
          type="boligsok"
          title="Registrer ditt eiendomssøk"
          bodyText={`<p>PrivatMegleren Eiendomssøk holder deg ukentlig oppdatert med aktuelle eiendommer.</p>`}
          href={routeConfig?.boligsok.url}
          bg="https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/registrer-ditt-eiendomssok.01.jpg"
        />
      </Sections>
    </>
  );
};

export default Home;

const Sections = styled.div`
  display: block;
  padding-bottom: 70px;
  width: 100%;
`;
